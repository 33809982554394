import React, { Component } from "react";
import styled from "styled-components";
import { Button, Card, Divider, Form, Input, message, Row } from "antd";
import { Link } from "react-router-dom";
import { Spacer } from "../../components/global";
import { loginUser } from "../../services/auth.services";
import Logo from "../../assets/images/logo.png";
import { getProfile } from "../../services/users.services";

class LoginPage extends Component {
  state = {
    loading: false,
  };

  authLogin(body) {
    this.setState({ loading: true });

    loginUser(body).then((res) => {
      if (res.token) {
        console.log(res.token);
        localStorage.setItem("accessToken", res.token);
        getProfile().then((res) => {
          console.log(res);
          res?.user?.actif
            ? this.props.history.push("/balises")
            : this.props.history.push("/login/first");
        });
      } else if (res.response.status === 401) {
        message.error(res.response.data.error);
        this.setState({ loading: false });
        localStorage.removeItem("accessToken");
      }
    });
  }

  render() {
    const { loading } = this.state;

    return (
      <>
        <LoginWrapper>
          <CardWrapper>
            <Card
              title={
                <div>
                  <center>
                    {" "}
                    <img src={Logo} alt="" style={{ height: 60, margin: 0 }} />
                    <br />
                    <h1 style={{ margin: 0 }}>Connexion</h1>
                    <h4 style={{ margin: 0 }}>Dashboard Client</h4>
                  </center>
                </div>
              }
              bordered={false}
            >
              <div style={{ padding: "0 10%" }}>
                <Row gutter={[12, 24]} justify="center">
                  <Form
                    onFinish={this.authLogin.bind(this)}
                    initialValues={
                      window.location.hostname === "localhost" && {
                        email: "dimitri@hotmail.fr",
                        password: "dimitri",
                      }
                    }
                  >
                    <Form.Item name="email">
                      <Input placeholder="Email" size="large" type="email" />
                    </Form.Item>
                    <Form.Item name="password">
                      <Input
                        placeholder="Mot de passe"
                        size="large"
                        type="password"
                      />
                    </Form.Item>{" "}
                    <Divider style={{ margin: 0 }} />
                    <Form.Item>
                      <Button
                        loading={loading}
                        size="large"
                        type="primary"
                        htmlType="submit"
                        block
                      >
                        Connexion
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Link to="/reset-password">Mot de passe oublié</Link>
                    </Form.Item>
                  </Form>

                  <Spacer height="5vh" />
                </Row>
              </div>
            </Card>
          </CardWrapper>
        </LoginWrapper>
      </>
    );
  }
}

// var body = {
//   email: "dimitri@hotmail.fr",
//   password: "dimitri",
// };

export default LoginPage;

const LoginWrapper = styled.div`
  background: #9C9583;
  position: relative;
  padding: 20px;
  height: ${window.innerHeight}px;
  // max-height: 100%;
  color: white;

  h1 {
      text-align: center;
      font-weight 800;
      margin: 0
  }
`;

const CardWrapper = styled.div`
  position: absolute;
  width: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
